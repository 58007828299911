import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { protectedApiCall, getToken } from '../../auth';
import '../../css/NewsletterEditor.css';
import { debounce } from "chart.js/helpers";
import newsletterTemplate from '../../templates/newsletterTemplate';
import announcementTemplate from '../../templates/announcementTemplate';
import newsletterTwoTemplate from '../../templates/tammooNewsletterTemplate';
import invitationTemplate from '../../templates/invitationTemplate';


const templateOptions = {
    'Newsletter': newsletterTemplate,
    'Newsletter2': newsletterTwoTemplate,
    'Announcement': announcementTemplate,
    'Invitation': invitationTemplate,
};

const NewsletterEditor = () => {
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [content, setContent] = useState(newsletterTemplate);
    const [version, setVersion] = useState('');
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [customTag, setCustomTag] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('Newsletter');
    const [testMode, setTestMode] = useState('test');
    const [directEmail, setDirectEmail] = useState('');

    const predefinedTags = ['Newsletter', 'Update', 'General', 'Voting'];

    useEffect(() => {
        fetchVersions();
    }, []);

    // Update content when template changes
    useEffect(() => {
        if (window.confirm('Switching templates will overwrite your current changes. Do you want to proceed?')) {
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setContent(templateOptions[selectedTemplate]);
        }
    }, [selectedTemplate]);

    // Fetch newsletter versions
    const fetchVersions = async () => {
        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/newsletters/versions`);
                return response.data;
            }, navigate);

            if (result) {
                setVersions(result);
            }
        } catch (error) {
            console.error('Error fetching newsletter versions:', error);
        }
    };

    const saveAndSendNewsletter = async () => {
        try {
            const savedNewsletter = await saveNewsletter();
            if (savedNewsletter) {
                const result = await protectedApiCall(async () => {
                    const editedContent = editorRef.current.getContent();
                    const fullContent = templateOptions[selectedTemplate].replace(
                        /<body>[\s\S]*<\/body>/,
                        `<body>${editedContent}</body>`
                    );
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/newsletters/send-newsletter`, {
                        content: fullContent,
                        tags: selectedTags,
                        testMode, // Pass the testMode flag
                    });
                    return response.data;
                }, navigate);

                if (result) {
                    alert('Newsletter saved and sent successfully!');
                }
            }
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };

    const debouncedSetContent = useRef(debounce((newContent) => {
        setContent(newContent);
    }, 300)).current;
    const saveNewsletter = async () => {
        if (!version && !selectedVersion) {
            alert('Please enter a version name.');
            return null;
        }

        try {
            const result = await protectedApiCall(async () => {
                const editedContent = editorRef.current.getContent();
                const fullContent = templateOptions[selectedTemplate].replace(
                    /<body>[\s\S]*<\/body>/,
                    `<body>${editedContent}</body>`
                );

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/newsletters/save`, {
                    content: fullContent,
                    version: version || selectedVersion,
                    tags: selectedTags
                });
                return response.data;
            }, navigate);

            if (result) {
                alert('Newsletter saved successfully!');
                await fetchVersions();
                return result;
            }
        } catch (error) {
            console.error('Error saving newsletter:', error);
            alert('Failed to save newsletter.');
        }
        return null;
    };

    const loadVersion = async (version) => {
        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/newsletters/${version}`);
                return response.data;
            }, navigate);

            if (result && result.content) {
                setContent(result.content);
                setSelectedVersion(version);
                setSelectedTags(result.tags || []);
                if (editorRef.current) {
                    editorRef.current.setContent(result.content);
                }
            }
        } catch (error) {
            console.error('Error loading newsletter version:', error);
        }
    };

    const resetToDefaultTemplate = () => {
        if (window.confirm('This will reset the content to the default template. Do you want to proceed?')) {
            setContent(templateOptions[selectedTemplate]);
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setSelectedTags([]);
        }
    };

    const sendNewsletter = async () => {
        try {
            // Validate email address for direct mode
            if (testMode === 'direct' && !directEmail) {
                alert('Please enter an email address for direct mode');
                return;
            }

            const result = await protectedApiCall(async () => {
                const editedContent = editorRef.current.getContent();
                const fullContent = templateOptions[selectedTemplate].replace(
                    /<body>[\s\S]*<\/body>/,
                    `<body>${editedContent}</body>`
                );

                const payload = {
                    content: fullContent,
                    tags: selectedTags,
                    mode: testMode,
                    emailAddress: testMode === 'direct' ? directEmail : null
                };

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/newsletters/send-newsletter`, payload);
                return response.data;
            }, navigate);

            if (result) {
                alert('Newsletter sent successfully!');
            }
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };



    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const getFullContent = () => {
        const editedContent = editorRef.current.getContent();
        return templateOptions[selectedTemplate].replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );
    };

    const handleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleCustomTagInput = (e) => {
        setCustomTag(e.target.value);
    };

    const addCustomTag = () => {
        if (customTag && !selectedTags.includes(customTag)) {
            setSelectedTags([...selectedTags, customTag]);
            setCustomTag('');
        }
    };

    return (
        <div className="newsletter-editor">
            <div className="editor-header">
                <div className="document-controls">
                    <div className="version-control">
                        <input
                            type="text"
                            placeholder="Version"
                            value={version}
                            onChange={(e) => setVersion(e.target.value)}
                        />
                        <select
                            className="version-select"
                            value={selectedVersion}
                            onChange={(e) => loadVersion(e.target.value)}
                        >
                            <option value="">Select version</option>
                            {versions.map((v) => (
                                <option key={v.version} value={v.version}>
                                    {v.version} - {new Date(v.date).toLocaleDateString()}
                                </option>
                            ))}
                        </select>
                        <button className="new-button" onClick={resetToDefaultTemplate}>New</button>
                    </div>
                    <div className="template-selection">
                        <select value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
                            {Object.keys(templateOptions).map(template => (
                                <option key={template} value={template}>{template}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="distribution-controls">
                    <div className="mode-selection">
                        <label><input type="radio" name="sendMode" value="test"
                                      checked={testMode === 'test'} onChange={(e) => setTestMode('test')}/> Test</label>
                        <label><input type="radio" name="sendMode" value="production"
                                      checked={testMode === 'production'} onChange={(e) => setTestMode('production')}/> Production</label>
                        <label><input type="radio" name="sendMode" value="direct"
                                      checked={testMode === 'direct'} onChange={(e) => setTestMode('direct')}/> Direct</label>
                        {testMode === 'direct' && (
                            <input type="email" placeholder="Email address"
                                   value={directEmail} onChange={(e) => setDirectEmail(e.target.value)}
                                   className="direct-email-input"/>
                        )}
                    </div>
                </div>

                <div className="tag-management">
                    <div className="tag-selection">
                        {predefinedTags.map(tag => (
                            <button key={tag} onClick={() => handleTagSelection(tag)}
                                    className={selectedTags.includes(tag) ? 'selected' : ''}>
                                {tag}
                            </button>
                        ))}
                        <input type="text" value={customTag} onChange={handleCustomTagInput}
                               placeholder="Custom tag" onKeyPress={(e) => e.key === 'Enter' && addCustomTag()}/>
                        <button onClick={addCustomTag}>+</button>
                    </div>
                </div>
            </div>

            <div className="editor-wrapper">
                <Editor
                    apiKey="2a21s1kjn13r7oo10wd0s74qjc98em3ec9ioth1ymk5bpkov"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={content}
                    init={{
                        height: "100%",
                        menubar: false,
                        plugins: [
                            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists',
                            'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                        ],
                        automatic_uploads: false, // Disable automatic uploads
                        images_dataimg_filter: function() {
                            return false; // Prevent blob creation
                        },
                        toolbar: `
                                    undo redo | blocks fontfamily fontsize | 
                                    bold italic underline strikethrough forecolor backcolor | 
                                    alignleft aligncenter alignright alignjustify | 
                                    bullist numlist outdent indent | 
                                    image media link table | 
                                    removeformat code fullscreen help
                                `
                    }}
                    onEditorChange={(newContent) => debouncedSetContent(newContent)}
                />

            </div>

            <div className="editor-footer">
                <button onClick={saveNewsletter}>Save</button>
                <button onClick={sendNewsletter}>Send</button>
                <button onClick={saveAndSendNewsletter}>Save & Send</button>
                <button onClick={togglePreview}>Preview</button>
            </div>
            {showPreview && (
                <div className="preview-modal">
                    <div className="preview-content" dangerouslySetInnerHTML={{ __html: getFullContent() }} />
                    <button className="close-button" onClick={togglePreview}>Close Preview</button>
                </div>
            )}
        </div>
    );
};

export default NewsletterEditor;
