import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ClassSelector from '../Classes/ClassSelector';
import DataTable from './DataTable';
import ThreeMonthCalendar from './ThreeMonthCalendar';
import '../../css/Home.css';
import Papa from 'papaparse';
import { DNA } from 'react-loader-spinner';
import {isTokenExpired, protectedApiCall, refreshAccessToken} from "../../auth";
import debounce from 'lodash/debounce';

const Dash = () => {
    const getStorageItem = (key, defaultValue) => {
        try {
            const item = localStorage.getItem(key);
            if (item === null) return defaultValue;
            return JSON.parse(item);
        } catch (error) {
            console.error(`Error reading ${key} from localStorage:`, error);
            localStorage.removeItem(key); // Clean up invalid data
            return defaultValue;
        }
    };
    const [terms, setTerms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(() =>
        getStorageItem('selectedClass', null)
    );

    const validateDateRange = (range) => {
        if (!range || !range[0] || !range[1] || !(range[0] instanceof Date) || !(range[1] instanceof Date)) {
            return [new Date(selectedYear, 0, 1), new Date(selectedYear, 11, 31)];
        }
        return range;
    };
    const [data, setData] = useState([]);
    const [role, setRole] = useState([]);
    const [currentDate, setCurrentDate] = useState(() => {
        const savedDate = localStorage.getItem('currentDate');
        try {
            return savedDate ? new Date(savedDate) : new Date();
        } catch (e) {
            console.error('Error parsing currentDate from localStorage:', e);
            return new Date();
        }
    });
    const [dateRange, setDateRange] = useState(() => {
        const defaultRange = [new Date(), new Date()];
        return getStorageItem('dateRange', defaultRange);
    });
    const [tableState, setTableState] = useState({ columns: [], rows: [] });
    const [selectedName, setSelectedName] = useState(() =>
        localStorage.getItem('selectedName') || null
    );
    const [showCalendar, setShowCalendar] = useState(false);
    const [recordDates, setRecordDates] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState(() =>
        getStorageItem('selectedTerms', [])
    );
    const [dateRangeKey, setDateRangeKey] = useState(0);
    const navigate = useNavigate();
    const displayName = useMemo(() => selectedName || 'class', [selectedName]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [showEditModal, setShowEditModal] = useState();
    const [selectedClassId, setSelectedClassId] = useState(() => {
        const savedClassId = localStorage.getItem('selectedClassId');
        return savedClassId ? JSON.parse(savedClassId) : null;
    });
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [renderKey, setRenderKey] = useState(0);
    const [selectedYear, setSelectedYear] = useState(() =>
        localStorage.getItem('selectedYear') || '2024'
    );

    const setStorageItem = (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(`Error saving ${key} to localStorage:`, error);
        }
    };

    const handleYearChange = (event = {}) => {
        const newYear = event.target ? event.target.value : selectedYear; // Use selectedYear as a fallback
        console.log('Year changed to:', newYear);
        
        // Update the year state and storage
        setSelectedYear(newYear);
        localStorage.setItem('selectedYear', newYear);
        
        // Clear selected terms
        setSelectedTerms([]);
        
        // Update the date range to the first day of the new year
        const startOfYear = new Date(parseInt(newYear), 0, 1);
        const endOfYear = new Date(parseInt(newYear), 11, 31);
        const newRange = [startOfYear, endOfYear];
        
        console.log('Updating date range for new year:', newRange);
        setDateRange(newRange);
        setCurrentDate(startOfYear);
        localStorage.setItem('dateRange', JSON.stringify(newRange));
        
        // Reset to page 1 and fetch new data
        setCurrentPage(1);
        fetchData();
    };


    useEffect(() => {
        Promise.all([
            fetchClasses(),
            fetchTerms()
        ]).then(() => {
            fetchData();
        });
    }, [selectedYear]);

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/dash'
            });
        }
    }, []);


    const filteredTerms = terms.filter(term => term.year === selectedYear);


    useEffect(() => {
        const savedClass = localStorage.getItem('selectedClass');

        if (savedClass) {
            console.log('GOT', savedClass);
            handleClassSelect(savedClass._id)
            try {
                const parsedClass = JSON.parse(savedClass);
                setSelectedClass(parsedClass);
                setRole(parsedClass.role || []); // Set the role if it exists
            } catch (e) {
                console.error('Error parsing saved class from localStorage', e);
            }
        }
    }, []);

    const handleEdit = (classId, record) => {
        setSelectedRecord(record);  // Store the selected record
        setShowEditModal(true);  // Open the modal
    };

    const handleSaveRecord = async (updatedRecord) => {
        const date = new Date(updatedRecord.date);
        const year = date.getFullYear().toString();
        const day = date.toISOString().split('T')[0];

        try {
            const result = await protectedApiCall(async () => {
                const response = await fetch(`${BASE_URL}/records/${selectedClass._id}/${year}/${day}/${updatedRecord._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(updatedRecord),
                });
                return response.json();
            }, navigate);

            if (result) {
                setData((prevData) => {
                    const newData = prevData.map((r) =>
                        r._id === result._id ? result : r
                    );
                    return [...newData];
                });
                setRenderKey((prevKey) => prevKey + 1);
                await fetchData();
            }
        } catch (error) {
            console.error('Error updating record:', error);
        }
    };

    const handleDelete = async (classId, record) => {
        // Extract the date portion (YYYY-MM-DD) and the year from the ISO string
        const date = new Date(record.date);
        const year = date.getFullYear().toString(); // Extract the year (e.g., 2024)
        const day = date.toISOString().split('T')[0]; // Extract the date part (e.g., "2024-08-09")

        // Log what you're about to delete
        console.log('GONNA DELETE', selectedClass._id, year, day, record._id);

        if (window.confirm("Are you sure you want to delete this item?")) {
            try {
                const response = await fetch(`${BASE_URL}/records/${selectedClass._id}/${year}/${day}/${record._id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.ok) {
                    // Update state to remove the deleted record
                    setData((prevData) => prevData.filter(r => r._id !== record._id));
                } else {
                    console.error('Failed to delete record');
                }
            } catch (error) {
                console.error('Error deleting record:', error);
            }
        }
    };


    useEffect(() => {
        const initializeData = async () => {
            const savedClass = localStorage.getItem('selectedClass');
            const savedRange = localStorage.getItem('dateRange');

            if (savedClass) {
                try {
                    const parsedClass = JSON.parse(savedClass);
                    setSelectedClass(parsedClass);
                    setRole(parsedClass.role || []);

                    if (savedRange) {
                        const parsedRange = JSON.parse(savedRange).map(date => new Date(date));
                        setDateRange(parsedRange);
                        setSelectedClassId(parsedClass._id);
                        setCurrentDate(parsedRange[0]);
                    }
                } catch (e) {
                    console.error('Error parsing saved data from localStorage', e);
                }
            }

            // Fetch initial data in sequence
            await fetchTerms();
            await fetchClasses();
            setSelectedName('class');
        };

        initializeData();
    }, []);

    const fetchClasses = async () => {
        const result = await protectedApiCall(async () => {
            const response = await axios.get(`${BASE_URL}/classes`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            return response.data;
        }, navigate);
        if (result) setClasses(result);
    };

    const fetchTerms = async () => {
        const result = await protectedApiCall(async () => {
            const response = await axios.get(`${BASE_URL}/terms`, {
                params: { year: selectedYear },
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            return response.data;
        }, navigate);
        if (result) setTerms(result.filter(term => term.year === selectedYear));
    };




    const fetchData = useCallback(async () => {
        if (!selectedClass) return;

        setIsLoading(true);
        console.log('Current dateRange in fetchData:', dateRange);
        console.log('localStorage dateRange:', JSON.parse(localStorage.getItem('dateRange')));
        
        // Get dates directly from state, skip validation for now
        const [startDate, endDate] = dateRange;
        console.log('Using dates:', { startDate, endDate });
        const start = startDate.toISOString().split('T')[0];
        const end = endDate.toISOString().split('T')[0];
        console.log('Making API call with dates:', { start, end });
        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(
                    `${BASE_URL}/classes/${selectedClass._id}/range/${start}/${end}`,
                    {
                        params: {
                            year: selectedYear,
                            page: currentPage,
                            limit: 100,
                            studentName: selectedName !== 'class' ? selectedName : ''
                        },
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                );
                return response.data;
            }, navigate);
            console.log('Got stuff, and now ... ')
            if (result) {
                const { totalRecords, currentPage, totalPages, records } = result;
                setData(records);
                setTotalRecords(totalRecords);
                setCurrentPage(currentPage);
                setTotalPages(totalPages);
                setRole(selectedClass.role || []);
                fetchRecordDates(selectedClass._id, selectedName);
            }
            console.log('... Done ');
        } catch (error) {
            console.error('Error fetching data:', error);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    }, [selectedClass, currentPage, selectedName, selectedYear]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchRecordDates = useCallback(async (classId, studentName = null) => {
        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(`${BASE_URL}/classes/${classId}/record-dates`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    params: { studentName: studentName === 'class' ? null : studentName }
                });
                return response.data;
            }, navigate);

            if (result) {
                setRecordDates(result.map(date => date.split('T')[0]));
            }
        } catch (error) {
            console.error('Error fetching record dates:', error);
            setRecordDates([]);
        }
    }, []);

    useEffect(() => {
        console.log('SETUP!!!!')
        setRecordCount(data.length);
    }, [data]);

    useEffect(() => {
        localStorage.setItem('selectedClass', JSON.stringify(selectedClass));
    }, [selectedClass]);

    useEffect(() => {
        localStorage.setItem('currentDate', JSON.stringify(currentDate));
    }, [currentDate]);

    useEffect(() => {
        localStorage.setItem('dateRange', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        localStorage.setItem('selectedName', selectedName);
    }, [selectedName]);

    const navigatePrevious = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const navigateNext = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const debouncedFetchData = useCallback(
        debounce(() => {
            fetchData();
        }, 300),
        [fetchData]
    );

    const handleDateRangeChange = (range) => {
        console.log('handleDateRangeChange received:', range);
        if (range.length === 2) {
            console.log('Setting new date range:', range);
            // Ensure we're working with fresh Date objects
            const newRange = range.map(d => new Date(d.getTime()));
            
            // Update the year if it's different from the selected dates
            const selectedYear = newRange[0].getFullYear().toString();
            if (selectedYear !== localStorage.getItem('selectedYear')) {
                console.log('Updating year to match selected dates:', selectedYear);
                setSelectedYear(selectedYear);
                localStorage.setItem('selectedYear', selectedYear);
                // Clear selected terms when year changes
                setSelectedTerms([]);
            }
            
            setDateRange(newRange);
            setCurrentDate(newRange[0]);
            setCurrentPage(1);
            // Store in localStorage immediately to track any potential changes
            localStorage.setItem('dateRange', JSON.stringify(newRange));
            console.log('Stored in localStorage:', JSON.parse(localStorage.getItem('dateRange')));
            console.log('Triggering fetch...');
            // Call fetchData directly instead of using debounce
            fetchData();
        }
    };


    const handleTermChange = (event) => {
        const selectedTermNumbers = Array.from(event.target.selectedOptions, option =>
            parseInt(option.value, 10)
        );
        setSelectedTerms(selectedTermNumbers);

        const selectedTermObjects = terms.filter(term =>
            selectedTermNumbers.includes(term.termNumber)
        );

        if (selectedTermObjects.length > 0) {
            const startDates = selectedTermObjects.map(term => new Date(term.startDate));
            const endDates = selectedTermObjects.map(term => new Date(term.endDate));
            const overallStartDate = new Date(Math.min(...startDates));
            const overallEndDate = new Date(Math.max(...endDates));

            const newRange = [new Date(overallStartDate), new Date(overallEndDate)];
            setDateRange(newRange);
            setCurrentDate(newRange[0]);
            setCurrentPage(1);
            localStorage.setItem('dateRange', JSON.stringify(newRange));

            // Call fetchData directly
            fetchData();

            localStorage.setItem('selectedTerms', JSON.stringify(selectedTermNumbers));
            localStorage.setItem('startDate', overallStartDate.toISOString());
            localStorage.setItem('endDate', overallEndDate.toISOString());
        }
    };


    const handleClassSelect = (selectedClass) => {
        setSelectedClass(selectedClass);
        localStorage.setItem('selectedClass', JSON.stringify(selectedClass));
        setSelectedClassId(selectedClass ? selectedClass._id : null);
    };

    const handleStateChange = useCallback((newState) => {
        setTableState(newState);
    }, []);

    const handleExportCSV = async () => {
        if (!selectedClass || dateRange.length !== 2) {
            return;
        }

        const [startDate, endDate] = dateRange;
        const start = startDate.toISOString().split('T')[0];
        const end = endDate.toISOString().split('T')[0];
        const exportEndpoint = `${BASE_URL}/classes/${selectedClass._id}/range/export/${start}/${end}?studentName=${selectedName !== 'class' ? selectedName : ''}`;

        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(exportEndpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                return response.data;
            }, navigate);

            if (result) {
                const { records } = result;
                const csvData = records.map(record => {
                    const rowData = {};
                    tableState.columns.forEach(col => {
                        if (record[col.key] !== undefined) {
                            rowData[col.label] = col.key === 'date' ? formatDate(record[col.key]) : record[col.key];
                        } else {
                            rowData[col.label] = '';
                        }
                    });
                    return rowData;
                });

                const csv = Papa.unparse(csvData);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'data.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error exporting CSV:', error);
        }
    };

    useEffect(() => {
        const savedName = localStorage.getItem('selectedName');
        setSelectedName(prevName => {
            if (savedName && prevName !== savedName) {
                return savedName;
            }
            return prevName;
        });
    }, []);

    const handleNameClick = useCallback((name) => {
        setSelectedName(prevName => prevName === name ? 'class' : name); // Ensure 'class' is used to denote no student selected
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };

    useEffect(() => {
        if (data.length > 0) {
            setFilteredData(data);
        }
    }, [data]);

    useEffect(() => {
        if (selectedName === null || selectedName === '') {
            setSelectedName('class');
            setFilteredData(data);
        } else {
            try {
                const filtered = data.filter(record => record.name && record.name.split(' ')[0] === selectedName);
                setFilteredData(filtered.length > 0 ? filtered : data);
            } catch (error) {
                console.error('Error filtering data:', error);
                setFilteredData(data);
            }
        }
    }, [data, selectedName]);

    return (
        <div className="home-container">
            <div className="main-content">
                <div className="left-column">
                    <div className="role-selector">
                        {role.map((person, index) => (
                            <button
                                key={index}
                                onClick={() => handleNameClick(person.firstName)}
                                className={selectedName === person.firstName ? 'selected' : 'unselected'}
                            >
                                {person.firstName} {person.lastName}
                            </button>
                        ))}
                    </div>
                    <div className="term-box">


                        <label htmlFor="term-select" className="term-select-text">Select Terms:</label>
                        <div className="term-sub_text">(Multiple Select)</div>
                        <select
                            className="select-button-home"
                            id="term-select"
                            multiple
                            onChange={handleTermChange}
                            value={selectedTerms}
                        >
                            {terms.map(term => (
                                <option key={term.termNumber} value={term.termNumber}>
                                    Term {term.termNumber}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="table-container">
                    <div className="class-and-navigation">
                        <div className="class-selector">
                            <label>Select A Class:</label>
                            <ClassSelector
                                classes={classes}
                                onSelect={handleClassSelect}
                                selectedClass={selectedClass}
                            />
                        </div>
                        <div className="year-selector">
                            <label>Select Year: </label>
                            <select
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className="navigation-controls">
                        <span className="date-container">
                            {Array.isArray(currentDate)
                                ? `${currentDate[0].toDateString()} - ${currentDate[1].toDateString()}`
                                : currentDate.toDateString()}
                        </span>
                            <button onClick={() => setShowCalendar(!showCalendar)}>
                                {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
                            </button>
                        </div>
                    </div>

                    {showCalendar && (
                        <div className="calendar-container">
                            <ThreeMonthCalendar
                                recordDates={recordDates}
                                onDateRangeChange={handleDateRangeChange}
                                dateRange={dateRange}
                            />
                        </div>
                    )}
                    {selectedClass && (
                        <div>
                            <div className="record-count">
                                Records for {displayName}: {`${(currentPage - 1) * 100 + 1} to ${Math.min(currentPage * 100, totalRecords)} of ${totalRecords}`}
                            </div>
                            {isLoading ? (
                                <div className="loader-container">
                                    <DNA
                                        visible={true}
                                        height="160"
                                        width="160"
                                        ariaLabel="dna-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="dna-wrapper"
                                    />
                                </div>
                            ) : (
                                <DataTable
                                    data={filteredData}
                                    onStateChange={handleStateChange}
                                    onEdit={(record) => handleEdit(selectedClassId, record)}
                                    onDelete={(record) => handleDelete(selectedClassId, record)}
                                    setData={setData}
                                    showEditModal={showEditModal}
                                    setShowEditModal={setShowEditModal}
                                    selectedRecord={selectedRecord}
                                    setSelectedRecord={setSelectedRecord}
                                    record={selectedRecord}
                                    onSave={handleSaveRecord}
                                />
                            )}
                            <div className="pagination-controls">
                                <button onClick={navigatePrevious} disabled={currentPage === 1}>Previous</button>
                                <button onClick={navigateNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    )}
                    <button onClick={handleExportCSV} className="export-button">Export CSV</button>
                </div>
            </div>
        </div>
    );
};

export default Dash;
