import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const themeStyles = {
    'royal-elegance': {
        '--primary-color': '#4B0082', /* Indigo */
        '--secondary-color': '#8A2BE2', /* Blue Violet */
        '--accent-color': '#e5b741', /* Medium Purple */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#F3E5F5', /* Very Light Purple */
        '--success-color': '#3CB371', /* Medium Sea Green */
        '--danger-color': '#B22222', /* Firebrick Red */
        '--border-color': '#ddd', /* Light Gray */
    },
    'ocean-breeze': {
        '--primary-color': '#0077B6',
        '--secondary-color': '#00B4D8',
        '--accent-color': '#90E0EF',
        '--text-color': '#023E8A',
        '--background-color': '#FFFFFF',
        '--light-background': '#CAF0F8',
        '--success-color': '#76C893',
        '--danger-color': '#d00000',
        '--border-color': '#ddd',
    },
    'sunset-glow': {
        '--primary-color': '#FF4E00',
        '--secondary-color': '#FF7F11',
        '--accent-color': '#FFD60A',
        '--text-color': '#4A4A4A',
        '--background-color': '#FFFFFF',
        '--light-background': '#FFF4E6',
        '--success-color': '#34A853',
        '--danger-color': '#EA4335',
        '--border-color': '#ddd',
    },
    'forest-calm': {
        '--primary-color': '#2C6E49', /* Forest Green */
        '--secondary-color': '#4C956C', /* Light Green */
        '--accent-color': '#81C784', /* Mint Green */
        '--text-color': '#1B4332', /* Dark Green */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#D8F3DC', /* Very Light Green */
        '--success-color': '#6A994E', /* Olive Green */
        '--danger-color': '#E63946', /* Red */
        '--border-color': '#ddd', /* Light Gray */
    },

    'citrus-fresh': {
        '--primary-color': '#FFA500', /* Orange */
        '--secondary-color': '#FF6347', /* Tomato */
        '--accent-color': '#FFD700', /* Gold */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#FFFACD', /* Lemon Chiffon */
        '--success-color': '#32CD32', /* Lime Green */
        '--danger-color': '#DC143C', /* Crimson */
        '--border-color': '#ddd', /* Light Gray */
    },
    'cool-mint': {
        '--primary-color': '#20B2AA', /* Light Sea Green */
        '--secondary-color': '#66CDAA', /* Medium Aquamarine */
        '--accent-color': '#AFEEEE', /* Pale Turquoise */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#E0FFFF', /* Light Cyan */
        '--success-color': '#3CB371', /* Medium Sea Green */
        '--danger-color': '#CD5C5C', /* Indian Red */
        '--border-color': '#ddd', /* Light Gray */
    },
    'earthy-tones': {
        '--primary-color': '#6B4226', /* Brown */
        '--secondary-color': '#8B5A2B', /* Saddle Brown */
        '--accent-color': '#D2B48C', /* Tan */
        '--text-color': '#2E2E2E', /* Dark Gray */
        '--background-color': '#FFFFFF', /* White */
        '--light-background': '#F5F5DC', /* Beige */
        '--success-color': '#556B2F', /* Dark Olive Green */
        '--danger-color': '#8B0000', /* Dark Red */
        '--border-color': '#ddd', /* Light Gray */
    },
};

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => localStorage.getItem('theme-choice') || 'ocean-breeze');

    useEffect(() => {
        const root = document.documentElement;
        const styles = themeStyles[theme];

        for (const style in styles) {
            root.style.setProperty(style, styles[style]);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
