import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, protectedApiCall } from '../../auth';
import { useNavigate } from 'react-router-dom';
import '../../css/Messaging.css';
import Calendar from 'react-calendar';

const Messaging = () => {
    const navigate = useNavigate();
    const [notificationData, setNotificationData] = useState({
        title: '',
        body: '',
        userId: '',
        scheduleTime: '',
        priority: 0,
        imageUrl: '',
        surveyChoices: []
    });
    const [scheduledNotifications, setScheduledNotifications] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editNotificationId, setEditNotificationId] = useState(null);
    const [saveAsNew, setSaveAsNew] = useState(false);
    const [notificationType, setNotificationType] = useState('BaseNotification'); // Default to BaseNotification
    const [surveyChoices, setSurveyChoices] = useState([]);

    const constructNotificationData = () => {
        const {title, body, userId, imageUrl} = notificationData;
        return {
            title,
            body,
            type: 'survey',
            choices: JSON.stringify(surveyChoices), // Convert choices to JSON string
            userId,
            imageUrl
        };
    };

    useEffect(() => {
        fetchScheduledNotifications();
    }, []);


    const addSurveyChoice = () => {
        setSurveyChoices([...surveyChoices, {label: '', value: ''}]);
    };

    const updateSurveyChoice = (index, field, value) => {
        const updatedChoices = surveyChoices.map((choice, i) =>
            i === index ? {...choice, [field]: value} : choice
        );
        setSurveyChoices(updatedChoices);
    };

    const deleteSurveyChoice = (index) => {
        const updatedChoices = surveyChoices.filter((_, i) => i !== index);
        setSurveyChoices(updatedChoices);
    };


    useEffect(() => {
        fetchScheduledNotifications();
    }, []);

    useEffect(() => {
        if (selectedDate) {
            const updatedEvents = scheduledNotifications.filter(job => {
                const jobDate = new Date(job.scheduledTime);
                return jobDate.toDateString() === selectedDate.toDateString();
            });
            setEventsForSelectedDate(updatedEvents);
        }
    }, [scheduledNotifications, selectedDate]);

    const fetchScheduledNotifications = async () => {
        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/schedule/scheduled-notifications`, {
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                });
                return response.data;
            }, navigate);

            if (result && result.success) {
                setScheduledNotifications(result.data);
            } else {
                console.error('Failed to fetch notifications');
            }
        } catch (error) {
            console.error('Error fetching scheduled notifications:', error);
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNotificationData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = constructNotificationData();

            const result = await protectedApiCall(async () => {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/schedule/schedule-notification`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                return response.data;
            }, navigate);

            if (result) {
                console.log('Notification processed:', result);
                setIsEditing(false);
                setEditNotificationId(null);
                setSaveAsNew(false);
                await fetchScheduledNotifications();
            }
        } catch (error) {
            console.error('Error processing notification:', error);
        }
    };

    const handleSendImmediately = async () => {
        try {
            const data = constructNotificationData();
            data.scheduleTime = null; // Ensure scheduleTime is not set

            const result = await protectedApiCall(async () => {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/messaging/send-notification`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                return response.data;
            }, navigate);

            if (result) {
                console.log('Notification sent immediately:', result);
                await fetchScheduledNotifications();
            }
        } catch (error) {
            console.error('Error sending notification immediately:', error);
        }
    };


    const handleDayClick = (date) => {
        setSelectedDate(date);
        const events = scheduledNotifications.filter(job => {
            const jobDate = new Date(job.scheduledTime);
            return jobDate.toDateString() === date.toDateString();
        });
        setEventsForSelectedDate(events);
    };

    const handleEditEvent = (event) => {
        const localDateTime = new Date(event.scheduledTime);
        const offset = localDateTime.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDateTime - offset).toISOString().slice(0, 16);

        setNotificationData({
            title: event.title,
            body: event.body,
            userId: event.userId,
            scheduleTime: localISOTime,
            priority: event.priority,
            imageUrl: event.imageUrl || '',
            surveyChoices: event.surveyChoices || '',
        });
        setNotificationType(event.type || 'BaseNotification');
        setIsEditing(true);
        setEditNotificationId(event.id);
    };

    const handleDeleteEvent = async (eventId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this notification?");
        if (!confirmDelete) return;

        try {
            const result = await protectedApiCall(async () => {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/schedule/delete-notification/${eventId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`
                        }
                    }
                );
                return response.data;
            }, navigate);

            if (result) {
                setScheduledNotifications(prev => prev.filter(event => event.id !== eventId));
                setEventsForSelectedDate(prev => prev.filter(event => event.id !== eventId));
            }
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };


    return (
        <div className="messaging-container">
            <h2>Manage Notifications</h2>
            <div className="form-wrapper">
                <form onSubmit={handleSubmit} className="notification-form">
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="title">Notification Title</label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                value={notificationData.title}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="userId">User ID</label>
                            <input
                                type="text"
                                id="userId"
                                name="userId"
                                value={notificationData.userId}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="body">Message Body</label>
                        <textarea
                            id="body"
                            name="body"
                            value={notificationData.body}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="scheduleTime">Schedule Time (optional)</label>
                            <input
                                type="datetime-local"
                                id="scheduleTime"
                                name="scheduleTime"
                                value={notificationData.scheduleTime}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="priority">Priority</label>
                            <input
                                type="number"
                                id="priority"
                                name="priority"
                                value={notificationData.priority}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="imageUrl">Image URL</label>
                        <input
                            type="url"
                            id="imageUrl"
                            name="imageUrl"
                            value={notificationData.imageUrl}
                            onChange={handleInputChange}
                        />
                    </div>
                    {notificationData.imageUrl && (
                        <div className="form-group">
                            <img src={notificationData.imageUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="notificationType">Notification Type</label>
                        <select
                            id="notificationType"
                            name="notificationType"
                            value={notificationType}
                            onChange={(e) => setNotificationType(e.target.value)}
                        >
                            <option value="BaseNotification">Base Notification</option>
                            <option value="SurveyNotification">Survey Notification</option>
                        </select>
                    </div>
                    {notificationType === 'SurveyNotification' && (
                        <div className="form-group">
                            <label>Survey Choices</label>
                            {surveyChoices.map((choice, index) => (
                                <div key={index} className="survey-choice">
                                    <input
                                        type="text"
                                        placeholder="Label"
                                        value={choice.label}
                                        onChange={(e) => updateSurveyChoice(index, 'label', e.target.value)}
                                        className="choice-input"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Value"
                                        value={choice.value}
                                        onChange={(e) => updateSurveyChoice(index, 'value', e.target.value)}
                                        className="choice-input"
                                    />
                                    <button type="button" className="button" onClick={() => deleteSurveyChoice(index)}>Delete</button>
                                </div>
                            ))}
                            <button type="button" className="button" onClick={addSurveyChoice}>Add Choice</button>
                        </div>
                    )}
                    <div className="form-actions">
                        <button type="submit" className="button send-button">
                            {isEditing ? (saveAsNew ? 'Save as New Notification' : 'Update Notification') : 'Send Notification'}
                        </button>
                        <button type="button" className="button send-immediately-button" onClick={handleSendImmediately}>
                            Send Immediately
                        </button>
                    </div>
                </form>
            </div>
            <div className="calendar-container">
                <h3>Scheduled Notifications</h3>
                <Calendar
                    onClickDay={handleDayClick}
                    tileContent={({ date, view }) => {
                        if (view === 'month') {
                            const scheduledOnDate = scheduledNotifications.filter(job => {
                                const jobDate = new Date(job.scheduledTime);
                                return jobDate.toDateString() === date.toDateString();
                            });
                            return scheduledOnDate.length > 0 ? <span>📅</span> : null;
                        }
                    }}
                />
            </div>
            {selectedDate && (
                <div className="events-list">
                    <h4>Events on {selectedDate.toDateString()}</h4>
                    {eventsForSelectedDate.map(event => {
                        const eventDate = new Date(event.scheduledTime);
                        const eventTime = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
                        const nextRunDate = new Date(event.nextRunAt).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });

                        return (
                            <div key={event.id} className="event-item">
                                <h5>{event.title}</h5>
                                <p>{event.body}</p>
                                <p><strong>Scheduled Time:</strong> {eventDate.toDateString()} {eventTime}</p>
                                <p><strong>Priority:</strong> {event.priority}</p>
                                <p><strong>Type:</strong> {event.type}</p>
                                <p><strong>Next Run At:</strong> {nextRunDate}</p>
                                {event.imageUrl && <img src={event.imageUrl} alt="Notification" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                <button type="button" className="button" onClick={() => handleEditEvent(event)}>Edit</button>
                                <button type="button" className="button" onClick={() => handleDeleteEvent(event.id)}>Delete</button>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
export default Messaging;
