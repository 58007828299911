import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/colors/purple.css';
import '../../css/CalendarCustom.css';

const ThreeMonthCalendar = ({ recordDates, onDateRangeChange, dateRange }) => {
    // Get the selected year from localStorage
    const selectedYear = localStorage.getItem('selectedYear') || new Date().getFullYear().toString();
    const [currentDate, setCurrentDate] = useState([new Date(), new Date()]);

    useEffect(() => {
        if (dateRange) {
            setCurrentDate(dateRange);
        }
    }, [dateRange]);

    const handleChange = (date) => {
        console.log('Calendar date selection:', date);
        const selectedDate = Array.isArray(date) ? date.map(d => new Date(d)) : new Date(date);
        console.log('Converted to Date objects:', selectedDate);
        setCurrentDate(selectedDate);
        if (Array.isArray(selectedDate) && selectedDate.length === 2) {
            console.log('Calling onDateRangeChange with:', selectedDate);
            onDateRangeChange(selectedDate);
        }
    };

    const mapDays = ({ date }) => {
        const dateHasRecord = hasRecord(date.toDate(), recordDates);
        let props = {};
        if (dateHasRecord) {
            props.className = "has-record";
            props.children = (
                <>
                    <span>{date.day}</span>
                    <div className="record-dot"></div>
                </>
            );
        }
        return props;
    };

    return (
        <div className="three-month-calendar">
            <Calendar
                numberOfMonths={3}
                value={currentDate}
                onChange={handleChange}
                calendarPosition="right"
                mapDays={mapDays}
                range
                weekStartDayIndex={1}
                displayWeekNumbers
                weekNumber="WK"
                minDate={`${selectedYear}-01-01`}
                maxDate={`${selectedYear}-12-31`}
            />
        </div>
    );
};

const hasRecord = (date, recordDates) => {
    const dateString = date.toISOString().split('T')[0];
    return recordDates.includes(dateString);
};

export default ThreeMonthCalendar;
