import React from 'react';

const templates = [
    {
        id: 'lesson-plan',
        name: 'Lesson Plan',
        template: `# {title}

## Learning Intentions
{learning_intentions}

## Overview
{overview}

## Key Activities
{activities}

## Resources
{resources}

## Assessment
{assessment}

## Differentiation
{differentiation}

## Outcomes
{outcomes}`
    },
    {
        id: 'email',
        name: 'Email',
        template: `# {subject}

Dear Parents/Guardians,

{introduction}

## Key Points
{key_points}

## Important Dates
{dates}

Kind regards,
{teacher_name}`
    },
    {
        id: 'research-template',
        name: 'Research Project',
        template: `# {topic}

## Research Question
{question}

## Methodology
{methodology}

## Data Collection
{data_collection}

## Analysis
{analysis}

## Conclusions
{conclusions}`
    },
    {
        id: 'unit-overview',
        name: 'Unit Overview',
        template: `# {unit_title}

## Duration
{duration}

## Outcomes
{outcomes}

## Assessment Tasks
{assessment_tasks}

## Weekly Breakdown
{weekly_breakdown}`
    },
    {
        id: 'page-of-research',
        name: 'Page Of Research',
        template: `# {research}

## Title
{title}

## Body
{body}

## References
{references}

## Quick Summary
{quick_summary}`
    }
];

const TemplateSelector = ({ selectedTemplate, onTemplateChange }) => {
    return (
        <div className="template-selector">
            <select
                value={selectedTemplate}
                onChange={(e) => onTemplateChange(e.target.value)}
                className="template-select"
            >
                <option value="">Select Template</option>
                {templates.map(template => (
                    <option key={template.id} value={template.id}>
                        {template.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TemplateSelector;
