import {jwtDecode} from "jwt-decode";
import axios from "axios";

export const setToken = (token, refreshToken) => {
    const decodedToken = jwtDecode(token);
    console.log('Decoded Access Token:', decodedToken);

    localStorage.setItem('token', token); // Save access token
    localStorage.setItem('refreshToken', refreshToken); // Save refresh token
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const clearToken = () => {
    localStorage.removeItem('token');
};

export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const logout = (navigate) => {
    clearToken();
    navigate('/login');
};

export const isAdmin = () => {
    // Example logic to check if the user is an admin
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role === 'admin';
};

export const isTokenExpired = () => {
    const token = localStorage.getItem('token');
    if (!token) return true;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        return null;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
            refreshToken
        });

        const { token, refreshToken: newRefreshToken } = response.data;

        // Update tokens in localStorage
        setToken(token, newRefreshToken);

        return token;
    } catch (error) {
        console.error('Token refresh failed:', error);
        // If refresh fails, clear tokens and redirect to login
        clearToken();
        return null;
    }
};

export const checkTokenValidity = async (navigate) => {
    const token = localStorage.getItem('token');
    if (!token) {
        if (navigate) navigate('/login');
        return false;
    }

    try {
        // First, check if token is expired locally
        if (isTokenExpired()) {
            // Attempt to refresh the token
            const newToken = await refreshAccessToken();
            if (!newToken) {
                if (navigate) navigate('/login');
                return false;
            }
            // If refresh successful, continue with the new token
        }

        // Validate token with backend
        await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/validate-token`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        return true;
    } catch (error) {
        console.error('Token validation failed:', error);
        if (navigate) navigate('/login');
        return false;
    }
};

export const protectedApiCall = async (apiCall, navigate) => {
    const isValid = await checkTokenValidity(navigate);
    if (isValid) {
        try {
            const result = await apiCall();
            return result !== undefined ? result : null;
        } catch (error) {
            console.error('Protected API Call Failed:', error);
            if (error.response) {
                console.error('Error Response:', error.response.data);
                console.error('Error Status:', error.response.status);
            }

            throw error;
        }
    }
    return null;
};



